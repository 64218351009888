import React from 'react';
import { Fragment } from 'react';

const DolhphinsSignupForm = () => {
  return (
    <Fragment>
      <iframe
        src="https://api.leadconnectorhq.com/widget/form/8qAWoVnPMjeR3jZyU0mg"
        style={{ width: '100%', height: '1400px', border: 'none', borderRadius: '3px' }}
        id="inline-8qAWoVnPMjeR3jZyU0mg"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="DolphinsSignup"
        data-height="1248"
        data-layout-iframe-id="inline-8qAWoVnPMjeR3jZyU0mg"
        data-form-id="8qAWoVnPMjeR3jZyU0mg"
        title="DolphinsSignup"
      />
      <script src="https://link.msgsndr.com/js/form_embed.js" />
    </Fragment>
  );
};

export default DolhphinsSignupForm;

