import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, PropsWithChildren } from 'react';
import { formatCompactNumber } from '../../../../utils/numbers';

const SocialMediaStatsIcon = ({ className }) => (
  <i
    style={{ fontSize: '40px' }}
    className={` ${className ? className : ''}`}
  ></i>
);

interface SocialMediaStatsItemBodyProps extends Queries.STRAPI_SOCIAL_MEDIA {
  platform: string;
}

const SocialMediaStatsItemBody: FC<SocialMediaStatsItemBodyProps> = ({
  platform,
  url,
  statisticsText,
  statisticNumber,
}) => {
  const icon = `bi bi-${platform.toLowerCase()}`;

  let followerText = '';
  if (statisticNumber && statisticsText) {
    const compactNumber = formatCompactNumber(statisticNumber);
    followerText = `${compactNumber}+ ${statisticsText}`;
  }

  let Body = (
    <div className="d-flex flex-column flex-md-row py-4 align-items-center">
      <SocialMediaStatsIcon className={icon} />
      {followerText && (
        <span className="flex-grow-1 fs-4 mt-2 mt-md-0">{followerText}</span>
      )}
    </div>
  );

  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className="d-block link-dark"
      >
        {Body}
      </a>
    );
  } else {
    return Body;
  }
};

const SocialMediaStatsItem: FC<Queries.STRAPI_SOCIAL_MEDIA> = socialMedia => {
  const { platform } = socialMedia;
  if (!platform) {
    return null;
  }

  return (
    <li className="list-group-item ">
      <SocialMediaStatsItemBody {...socialMedia} platform={platform} />
    </li>
  );
};

const SocialMediaStats = () => {
  const { allStrapiSocialMedia } = useStaticQuery<{
    allStrapiSocialMedia: Queries.STRAPI_SOCIAL_MEDIAConnection;
  }>(graphql`
    query SocialMediaStats {
      allStrapiSocialMedia {
        nodes {
          statisticNumber
          statisticsText
          platform
          url
        }
      }
    }
  `);

  if (!allStrapiSocialMedia || allStrapiSocialMedia.nodes.length === 0) {
    return null;
  } else {
    return (
      <ul className="list-group list-group-flush gap-2">
        {allStrapiSocialMedia.nodes.map(socialMedia => (
          <SocialMediaStatsItem key={socialMedia.platform} {...socialMedia} />
        ))}
      </ul>
    );
  }
};

export default SocialMediaStats;
