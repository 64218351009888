import React from 'react';

const CoachingSignupForm = () => {
  return (
    <>
      <h2 className="h1 text-center">Ready to Start Your Journey?</h2>
      <p>
        {' '}
        If my approach resonates with you, let’s connect and schedule your first
        appointment. Together, we can explore how to align your vision with your
        authentic self.
      </p>
      <iframe
        src="https://api.leadconnectorhq.com/widget/form/yaE38AlMMj2sTtDxqYoP"
        style={{
          width: '100%',
          height: '1500px',
          border: 'none',
          borderRadius: '3px',
        }}
        id="inline-yaE38AlMMj2sTtDxqYoP"
        data-layout={{ id: 'INLINE' }}
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="Signup"
        data-height="962"
        data-layout-iframe-id="inline-yaE38AlMMj2sTtDxqYoP"
        data-form-id="yaE38AlMMj2sTtDxqYoP"
        title="Signup"
      ></iframe>

      <script src="https://link.msgsndr.com/js/form_embed.js"></script>
    </>
  );
};

export default CoachingSignupForm;
