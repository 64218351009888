import React from 'react';

const ExploreWithMeSignupForm = () => {
  return (
    <>
      <h4 className="text-center h1">Ready to Start Your Journey?</h4>
      <iframe
        src="https://api.leadconnectorhq.com/widget/form/nSn5nF3W1pnEbUvbvlcn"
        style={{
          width: '100%',
          height: '2000px',
          border: 'none',
          borderRadius: '3px',
          overflow: 'hidden',
        }}
        id="inline-nSn5nF3W1pnEbUvbvlcn"
        data-layout="{'id':'INLINE'}"
        data-trigger-type="alwaysShow"
        data-trigger-value=""
        data-activation-type="alwaysActivated"
        data-activation-value=""
        data-deactivation-type="neverDeactivate"
        data-deactivation-value=""
        data-form-name="SignupExploreWithMe"
        data-height="1685"
        data-layout-iframe-id="inline-nSn5nF3W1pnEbUvbvlcn"
        data-form-id="nSn5nF3W1pnEbUvbvlcn"
        title="SignupExploreWithMe"
        scrolling="no"
      />
      <script src="https://link.msgsndr.com/js/form_embed.js" />
    </>
  );
};

export default ExploreWithMeSignupForm;

