import { graphql } from 'gatsby';
import React from 'react';
import HtmlContent from '../html-content';
import Carousel from '../carousel';
import './style.scss';

/**
 * This is the content for the service page. It displays the Strapi layouts.service-page-content component.
 *
 */
const ServicePageContent = ({ text_1, text_2, image_1, image_2 }) => {
  return (
    <div className="c-service-page-content">
      <div className="row mt-4">
        <div className="col-md-6 mt-md-3">
          <HtmlContent content={text_1} />
        </div>
        <div className="col-md-6 mt-md-3">
          <div className="rounded rounded-1" style={{ overflow: 'hidden  ' }}>
            <Carousel hideControls animationHandler="fade" images={image_1} />
          </div>
        </div>
      </div>
      <div className="row mt-2 mt-md-5">
        <div className="col-md-6 mt-2 mt-md-3">
          <HtmlContent content={text_2} />
        </div>
        <div className="col-md-6 mt-md-3 order-md-first">
          <div className="rounded rounded-1" style={{ overflow: 'hidden  ' }}>
            <Carousel hideControls images={image_2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment ServicePageContentFragment on STRAPI__COMPONENT_LAYOUT_SERVICE_PAGE_CONTENT {
    text_1 {
      data {
        childMarkdownRemark {
          html
        }
      }
    }
    text_2 {
      data {
        childMarkdownRemark {
          html
        }
      }
    }
    image_1 {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            sizes: "(max-width: 576px) 540px, (max-width: 768px) 720px, (max-width: 992px) 960px, (max-width: 1200px) 1140px, 1320px"
            aspectRatio: 1.3
          )
        }
      }
    }
    image_2 {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            sizes: "(max-width: 576px) 540px, (max-width: 768px) 720px, (max-width: 992px) 960px, (max-width: 1200px) 1140px, 1320px"
            aspectRatio: 1.3
          )
        }
      }
    }
  }
`;

export default ServicePageContent;
