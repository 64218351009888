import React from 'react';
import {
  Accordion,
  Container,
  Card,
  ListGroup,
  Row,
  Col,
  Button,
} from 'react-bootstrap';
import Banner from '../../components/banner';
import { graphql, useStaticQuery } from 'gatsby';
import ServicePageContent from '../../components/service-page-content';
import NotifyMeForm from '../../components/notify-me-form';
import Section from '../../components/section';
import wrapWithLayout from '../../components/layout/wrapWithLayout';
// eslint-disable-next-line no-unused-vars
import * as PageSeoFragment from '../../fragments/pageSeo';
// eslint-disable-next-line no-unused-vars
import * as productFragment from '../../fragments/product';
import { CheckoutForm } from '../../components/checkout';
import CoachingSignupForm from '../../components/signup-form/coaching';
import ResponsiveImage from '../../components/responsive-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const SpiritualAwakeningCoachingPage = wrapWithLayout(({ data }) => {
  const banner = data?.strapiSpiritualAwakeningCoachingPage?.banner;
  const content = data?.strapiSpiritualAwakeningCoachingPage?.Content;
  const product = data?.strapiSpiritualAwakeningCoachingPage?.product;
  const saraOnHorseImage = getImage(data?.saraOnHorseImage?.gatsbyImageData);
  const saraWithChild = getImage(data?.saraWithChild?.gatsbyImageData);
  const mountainsAndGreenImage = getImage(
    data?.mountainsAndGreenImage?.gatsbyImageData
  );

  return (
    <>
      {banner && <Banner {...banner} />}
      <Container>
        {content && <ServicePageContent {...content} />}
        <Section title={'Welcome to My Coaching Journey'} alignTitle={'center'}>
          <Row className="mt-3">
            <Col>
              <Card.Text className="mb-4">
                As a passionate coach, I have always been driven to help
                individuals believe in themselves, pursue their desires, and
                provide unwavering support along their journey. I consider
                myself an alchemist of energy—transforming and activating
                potential in those I work with. I serve as a mirror for personal
                growth, and while this may trigger some emotions, it often leads
                to profound gratitude from those ready to embrace change.
              </Card.Text>
              <Card.Text className="mb-4">
                My formal coaching journey began in 2018. Since then, I have
                embarked on an extensive learning path encompassing coaching
                techniques, exploring various disciplines of personal
                development, mindfulness, and holistic wellness, and with that
                comes all the tech part and marketing strategies. As I explored
                various methodologies, I also pursued my diverse passions,
                striving to create balance in my life. Gradually, I cultivated a
                lifestyle that aligns authentically with my values, guided by
                love and passion.
              </Card.Text>

              <div className="text-center w-100">
                <GatsbyImage image={saraOnHorseImage} alt="Sara on horse" />
              </div>
              <Card.Text className="mb-4">
                Of course, this path hasn't always been easy. I have faced
                challenges, moments of emotional struggle, and periods of
                stagnation. Yet, with perseverance, these difficult times have
                become less frequent. Healing is a journey, often messy but
                always worthwhile. I believe in the power of learning and
                experiencing new cultures, as well as embracing the vastness of
                the world. This journey has allowed me to understand and love
                myself better, enabling me to become a role model for
                authenticity.
              </Card.Text>
              <Card.Text className="mb-4">
                Happiness isn't merely a choice; it is a frequency—a way of
                being. Children and nature exemplify this innate joy, while
                adults often complicate it. As an empath, I've spent significant
                time in solitude to reconnect with my true self, to be able to
                tell apart what is mine and what is not. I believe quality time
                alone is essential for mental well-being, allowing you to
                self-reflect, daydream, and explore one's passions.
              </Card.Text>
              <Card.Text>
                While my life story is rich with experiences, I embrace the
                lessons learned along the way. While others see me as a beacon
                of light and inspiration. My genuine passion for helping others
                shines through in every interaction. I'm known for my
                compassionate listening, insightful feedback, I excel at
                creating a safe and supportive environment where you will feel
                heard, valued, and empowered to make meaningful changes in your
                life.
              </Card.Text>
            </Col>
          </Row>
        </Section>
        <div className="text-center">
          <GatsbyImage image={saraWithChild} alt="Sara with child" />
        </div>
        <Section title={'Course Offerings'} alignTitle={'center'}>
          <Row className="mt-3">
            <Col>
              <Card.Text>
                I offer a range of life coaching courses designed to meet you
                wherever you are on your journey:
              </Card.Text>
              <ListGroup variant="flush" className="mt-2 mb-4">
                <ListGroup.Item>
                  <strong>Awaken Your Spirit:</strong> A course focused on
                  spiritual growth, mindfulness, and connecting with your inner
                  self.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Personal Empowerment:</strong> This course helps you
                  build confidence, overcome obstacles, and become the best
                  version of yourself.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Holistic Wellness:</strong> A comprehensive approach
                  to wellness that covers physical, emotional, and spiritual
                  well-being.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Fulfillment and Balance:</strong> Learn how to set
                  meaningful goals, pursue your passions, and create a balanced,
                  fulfilling life.
                </ListGroup.Item>
              </ListGroup>
              <Card.Text>
                Join me on this transformative journey and unlock the incredible
                potential within you. With my guidance, you will awaken your
                spirit, become empowered, and live a life of purpose and
                fulfillment. I look forward to walking this path with you.
              </Card.Text>
              <div className="text-center">
                <Button
                  size="lg"
                  variant="primary"
                  onClick={() =>
                    window.scrollTo({
                      top: document.getElementById('form').offsetTop,
                      behavior: 'smooth',
                    })
                  }
                  className="mt-4"
                >
                  Sign Up Now
                </Button>
              </div>
            </Col>
          </Row>
        </Section>
        <Section title={'Why Choose Spiritual Coaching?'} alignTitle={'center'}>
          <Row className="mt-3">
            <Col md={6}>
              <Card.Text>
                Truly we are all spiritual beings connected to something
                greater. I'm committed to work with those who resonate with this
                understanding. It's important to stay open-minded and trust your
                intuition as you navigate this journey.
              </Card.Text>
            </Col>
            <Col md={6}>
              <GatsbyImage
                image={mountainsAndGreenImage}
                alt="Mountains and green"
              />
            </Col>
          </Row>
        </Section>
        <Section title={'Coaching Services'} alignTitle={'center'}>
          <Row className="mt-3">
            <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
              <Card>
                <Card.Body>
                  <Card.Title>First Time Coaching</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    <em>*possible only once</em>
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Price: 222 EUR</strong> (60-80 minutes)
                  </Card.Text>
                  <Card.Text>
                    The first session is via Zoom or in person, where we'll
                    explore your situation, challenges, needs and develop an
                    action plan together.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
              <Card>
                <Card.Body>
                  <Card.Title>Quick SOS Coaching</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    <em>for existing customers only</em>
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>Price: 155 EUR</strong> (20 minutes)
                  </Card.Text>
                  <Card.Text>
                    Available via WhatsApp, Telegram, Viber, or iCall. You can
                    call me and if I don't pick up, I'll reach out as soon as
                    possible to address your challenges directly.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
              <Card>
                <Card.Body>
                  <Card.Title>3-Month Package</Card.Title>
                  <Card.Text>
                    <strong>Price: 3333 EUR</strong> (9 sessions) or 333 EUR
                    monthly for 12 months.
                  </Card.Text>
                  <Card.Text>
                    Includes three Zoom meetings per month, email support, and a
                    complimentary SOS call, as well as two in-person coaching
                    sessions with horses.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6} lg={4} className="mb-4 mb-md-0">
              <Card>
                <Card.Body>
                  <Card.Title>Business Coaching</Card.Title>
                  <Card.Text>
                    <strong>Price: 9870 EUR</strong> (or 2777 EUR in 4 payments)
                  </Card.Text>
                  <Card.Text>
                    This package consists of six in-person meetings with your
                    team, 12 Zoom sessions, and a day of group coaching with
                    horses in Slovenia.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="mb-4 mb-md-0">
              <Card>
                <Card.Body>
                  <Card.Title>
                    ALL INN Retreat in Slovenia and/or Croatia (1:1)
                  </Card.Title>
                  <Card.Text>
                    <strong>Price:</strong> Upon request
                  </Card.Text>
                  <Card.Text>
                    Experience intensive, personalized coaching in a serene
                    environment, focusing on self-discovery, connection with
                    nature, inner peace and healthy self love.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <h4>What to Expect from the Retreat:</h4>
                      </Accordion.Header>
                      <Accordion.Body>
                        <p>
                          Four Zoom calls prior to the retreat to customize your
                          experience. Ten days of individually organized
                          activities across Slovenia and Croatia, focused on
                          your personal growth with a personal coach.
                          High-quality services as accommodations, tranquil
                          settings, and nutritious meals prepared from fresh
                          local ingredients. Opportunities for unforgettable
                          experiences and remarkable personal development,
                          including confronting fears and embracing self-love.
                          Embrace this transformative journey to find inner
                          peace, abundance, and gratitude. You deserve time to
                          heal and rejuvenate.
                        </p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Section>

        <div id="form">
          <CoachingSignupForm />
        </div>
      </Container>
    </>
  );
});

export default SpiritualAwakeningCoachingPage;

export const query = graphql`
  query {
    strapiSpiritualAwakeningCoachingPage {
      seo {
        ...PageSeoFragment
      }
      banner {
        ...BannerFragment
      }
      Content {
        ...ServicePageContentFragment
      }
      product {
        ...ProductFragment
      }
    }
    saraOnHorseImage: cloudinaryMedia(
      publicId: { eq: "large_U156055_22b50f5e8f_975683cdd1" }
    ) {
      publicId
      gatsbyImageData(height: 500)
    }
    saraWithChild: cloudinaryMedia(publicId: { eq: "U155680_c1c8297ac8" }) {
      publicId
      gatsbyImageData(height: 500)
    }
    mountainsAndGreenImage: cloudinaryMedia(
      publicId: { eq: "large_IMG_7860_eec8737dde" }
    ) {
      publicId
      gatsbyImageData(height: 500)
    }
  }
`;
