import React from 'react';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

/**
 * Creates a responsive image component that switches between mobile and desktop images
 * @param {string} mobile - Mobile image data from GraphQL query
 * @param {string} desktopImage - Desktop image data from GraphQL query
 * @returns {React.Component} A component that displays different images for mobile and desktop viewports
 */
function ResponsiveImage({ mobile, desktop, alt, className, ...imageProps }) {
  if (!mobile || !desktop) {
    console.warn('Missing image data for ResponsiveImage component');
    return null;
  }

  return (
    <>
      <div className={`d-block d-md-none  ${className || ''}`}>
        <img src={mobile} alt={alt} {...imageProps} style={{ maxWidth: '100%' }}/>
      </div>
      <div className={`d-none d-md-block ${className || ''}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={desktop} alt={alt} {...imageProps} style={{ maxWidth: '100%' }} />
      </div>
    </>
  );
}

export default ResponsiveImage;
