import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { InstagramEmbed } from 'react-social-media-embed';

const InstagramReels = ({ urls }) => {
  return (
    <Row>
      {urls.map((url, index) => (
        <Col key={index} lg={6} xs={12} className="mb-4">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <InstagramEmbed url={url} width={550} />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default InstagramReels;
