import React from 'react';

interface StripedTableProps {
  columnHeaders: string[];
  data: string[][];
}

const StripedTable = ({ columnHeaders, data }: StripedTableProps) => {
  return (
    <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
      <table className="table table-striped ">
        <thead className="fs-2">
          <tr>
            {columnHeaders.map((header, index) => (
              <th key={header} scope="col">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="fs-4">
          {data.map((row, index) => (
            <tr key={`row ${index}`}>
              {row.map((cell, index) => {
                if (index === 0) {
                  return (
                    <th
                      key={`${index}-${cell}`}
                      scope="row"
                      className="fw-normal"
                    >
                      {cell}
                    </th>
                  );
                } else {
                  return <td key={`${index}-${cell}`}>{cell}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StripedTable;
