exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collaboration-index-jsx": () => import("./../../../src/pages/collaboration/index.jsx" /* webpackChunkName: "component---src-pages-collaboration-index-jsx" */),
  "component---src-pages-collaboration-prices-index-jsx": () => import("./../../../src/pages/collaboration/prices/index.jsx" /* webpackChunkName: "component---src-pages-collaboration-prices-index-jsx" */),
  "component---src-pages-explore-with-me-index-jsx": () => import("./../../../src/pages/explore-with-me/index.jsx" /* webpackChunkName: "component---src-pages-explore-with-me-index-jsx" */),
  "component---src-pages-handwritten-letter-index-jsx": () => import("./../../../src/pages/handwritten-letter/index.jsx" /* webpackChunkName: "component---src-pages-handwritten-letter-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-quick-coaching-index-jsx": () => import("./../../../src/pages/quick-coaching/index.jsx" /* webpackChunkName: "component---src-pages-quick-coaching-index-jsx" */),
  "component---src-pages-sataya-dolphins-index-jsx": () => import("./../../../src/pages/sataya-dolphins/index.jsx" /* webpackChunkName: "component---src-pages-sataya-dolphins-index-jsx" */),
  "component---src-pages-spiritual-awareness-coaching-index-jsx": () => import("./../../../src/pages/spiritual-awareness-coaching/index.jsx" /* webpackChunkName: "component---src-pages-spiritual-awareness-coaching-index-jsx" */),
  "component---src-templates-collaborate-hidden-page-js": () => import("./../../../src/templates/collaborate-hidden-page.js" /* webpackChunkName: "component---src-templates-collaborate-hidden-page-js" */)
}

