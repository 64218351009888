import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Banner from '../../components/banner';
import ServicePageContent from '../../components/service-page-content';
import NotifyMeForm from '../../components/notify-me-form';
import { CheckoutForm } from '../../components/checkout';
import useIsServiceAvailable from '../../hooks/useIsServiceAvailable';
import HtmlContent from '../../components/html-content';
import wrapWithLayout from '../../components/layout/wrapWithLayout';
// eslint-disable-next-line no-unused-vars
import * as productFragment from '../../fragments/product';
// eslint-disable-next-line no-unused-vars
import * as PageSeoFragment from '../../fragments/pageSeo';
import './style.scss';
import ExploreWithMeSignupForm from '../../components/signup-form/explore-with-me';
import ExploreWithMePackages from '../../components/explore-with-me/packages';
const ExploreWithMePage = wrapWithLayout(({ data }) => {
  const banner = data?.strapiExploreWithMe?.banner;
  const content = data?.strapiExploreWithMe?.content;
  const summary = data?.strapiExploreWithMe?.summary_section;
  const product = data?.strapiExploreWithMe?.product;

  return (
    <>
      {banner && <Banner {...banner} subtitle="guided extraordinary travels" />}
      <div className="container">
        {content && <ServicePageContent {...content} />}
        {summary && (
          <div className="mt-4 mt-md-5">
            <h2 className="text-md-center">{summary.title}</h2>
            <div className="mt-md-3">
              <HtmlContent content={summary.text} />
            </div>
          </div>
        )}
        <ExploreWithMePackages />
        <div className="mt-4">
          <ExploreWithMeSignupForm />
        </div>
      </div>
    </>
  );
});

export default ExploreWithMePage;

export const query = graphql`
  query {
    strapiExploreWithMe {
      seo {
        ...PageSeoFragment
      }
      banner {
        ...BannerFragment
      }
      product {
        ...ProductFragment
      }
      content {
        ...ServicePageContentFragment
      }
      summary_section {
        title
        text {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
