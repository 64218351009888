import React from 'react';
import SingleSponsoredPost from '../../collaboration/prices/sponsoredPosts/singleSponsoredPost';

const ExploreWithMePackages = () => {
  const height = 200;
  return (
    <>
      <div className="row row-cols-1 row-cols-md-3 mb-3 text-center mt-md-3">
        <SingleSponsoredPost
          color="secondary"
          minHeight={height}
          title="Half day"
          price="222 €"
          features={['cca. 3-4 hours']}
          buttonText="Sign up for free"
        />
        <SingleSponsoredPost
          color="primary"
          minHeight={height}
          title="All day"
          price="444 €"
          features={['cca. 6-8 hours']}
          buttonText="Get started"
        />
        <SingleSponsoredPost
          color="tertiary"
          minHeight={height}
          title="Several days"
          price="888 € per day"
          features={['max. 5 person']}
          buttonText="Get started"
        />
      </div>
      <div className="row text-center">
        <div className="col-12">
          <p className="fw-lighter">
            *Possible pick up/drop off from airport Ljubljana (Joze Pucnik)
            <br />
            **Price is per person.
            <br />
            ***Food, drinks, entrances, fees, accommodation or any other
            additional costs are not included in the price.
          </p>
        </div>
      </div>
    </>
  );
};

export default ExploreWithMePackages;
