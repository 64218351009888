import React from 'react';
import PropTypes from "prop-types";

const SingleSponsoredPost = ({ title, price, features, color, minHeight= 400 }) => (
  <div
    className={`c-single-sponsored-post c-single-sponsored-post--${color} col`}
  >
    <div className={`card mb-4 rounded-3 shadow-sm`} style={{ minHeight }}>
      <div className={`card-header py-3`}>
        <h4 className="my-0 fw-light h1">{title}</h4>
      </div>
      <div className="card-body d-flex flex-column">
        <span className="card-title pricing-card-title h1 fw-bold my-2">
          {price}
        </span>
        <ul className=" mt-3 mb-4 text-start flex-grow-1">
          {features.map((feature, index) => (
            <li className="my-2" key={index}>
              {feature}
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

SingleSponsoredPost.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  minHeight: PropTypes.string,
};

SingleSponsoredPost.defaultProps = {
  minHeight: '400px',
};

export default SingleSponsoredPost;

