import { Script } from 'gatsby';
import React from 'react';

const CollaborationForm = () => (
  <div>
    <h2 className="text-center h1">Contact me</h2>
    <p>
      All my offers have limited spots based on my schedule and possibilities to
      provide the best service for my clients. 
      
      Become an active client of mine to have the excesses
      of a live and real time with me.
    </p>
    <iframe
      src="https://api.leadconnectorhq.com/widget/form/6ZX7ql4ypYwg5NSB9Z1z"
      style={{
        width: '100%',
        height: '100%',
        border: 'none',
        borderRadius: '3px',
      }}
      id="inline-6ZX7ql4ypYwg5NSB9Z1z"
      data-layout="{'id':'INLINE'}"
      data-trigger-type="alwaysShow"
      data-trigger-value=""
      data-activation-type="alwaysActivated"
      data-activation-value=""
      data-deactivation-type="neverDeactivate"
      data-deactivation-value=""
      data-form-name="CollaborateForm"
      data-height="1320"
      data-layout-iframe-id="inline-6ZX7ql4ypYwg5NSB9Z1z"
      data-form-id="6ZX7ql4ypYwg5NSB9Z1z"
      title="CollaborateForm"
    />
    <Script id="collab-form" src="https://link.msgsndr.com/js/form_embed.js" />
  </div>
);

export default CollaborationForm;
