import React, { useState } from 'react';
import Layout from '../../components/layout';
import DolhphinsSignupForm from '../../components/dolphins/signupForm';
import { InstagramEmbed } from 'react-social-media-embed';
import InstagramReels from '../../components/instagram-reels';

const SatayaDolphinsPage = () => {
  const [showForm, setShowForm] = useState(false);
  const seo = {
    metaTitle: 'Sataya Dolphin Swim',
    metaDescription:
      "Experience life-changing encounters with wild dolphins in Sataya's heart-shaped reef. Join our intimate Red Sea retreats for unforgettable connections.",
    metaKeywords:
      "swim with wild dolphins, Sataya reef, Red Sea dolphins, dolphin retreat Egypt, wild dolphin encounters, spinner dolphins Egypt, Red Sea swimming, dolphin spiritual retreat, Egypt dolphin experience,heart-shaped reef, family dolphin vacation, women's retreat Egypt, self-love journey, dolphin meditation, yacht retreat Red Sea, Hamata Egypt, eco-friendly dolphin swimming, natural dolphin habitat, digital detox retreat,how to swim with wild dolphins in Egypt, best time to visit Sataya reef, Red Sea dolphin season, cost of dolphin retreat Egypt, women's spiritual retreat with dolphins, family-friendly dolphin programs Red Sea, Sataya reef accommodation, dolphin watching Egypt, sleeping under stars Egypt, dolphin consciousness experience,goddess retreat dolphins, family dolphin program, healing with dolphins, transformative water retreat, dolphin connection experience, spiritual ocean retreat, women's empowerment dolphins, self-discovery retreat Egypt, family bonding nature, marine meditation retreat,wild dolphin interaction, small group retreats, yacht accommodation Egypt, snorkeling Red Sea, coral reef exploration, marine life encounters, stargazing Egypt, off-grid experience, sustainable tourism Egypt, dolphin photography,heart-shaped reef, family dolphin vacation, women's retreat Egypt, self-love journey, dolphin meditation, yacht retreat Red Sea, Hamata Egypt, eco-friendly dolphin swimming, natural dolphin habitat, digital detox retreat",
    shareImage: {
      localFile: {
        url: 'https://res.cloudinary.com/div038xr5/image/upload/t_OG image/v1736679347/ss-creations/dolphins/8f5af226-ef7a-401a-9e0f-fd37f86b2179_smfwqy.jpg',
      },
    },
  };

  const toggleForm = () => setShowForm(!showForm);

  return (
    <Layout seo={seo}>
      {/* Hero Section */}
      <section className="position-relative vh-100">
        <div
          className="position-absolute w-100 h-100"
          style={{
            background: 'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.5))',
          }}
        />
        <img
          src="https://res.cloudinary.com/div038xr5/image/upload/c_fill,w_2500,h_1406,ar_16:9/v1736679347/ss-creations/dolphins/ooo_sbbm8x.jpg"
          alt="Dolphins swimming in crystal clear waters"
          className="w-100 h-100 object-fit-cover position-absolute"
        />
        <div className="position-relative h-100 container d-flex flex-column justify-content-center text-white">
          <div className="row">
            <div className="col-lg-8">
              <h1 className="display-2 fw-bold mb-4">
                Swim with Wild Dolphins in Their Natural Habitat
              </h1>
              <p className="lead mb-4">
                Experience the magic of connecting with free dolphins in the
                heart-shaped Sataya Reef of the Red Sea. A transformative
                journey that will open your heart to pure joy and unconditional
                love.
              </p>
              <button
                className="btn btn-lg btn-primary"
                onClick={() =>
                  document
                    .getElementById('experienceSection')
                    .scrollIntoView({ behavior: 'smooth' })
                }
              >
                Start Your Journey
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="">
        {/* Experience Section */}
        <div id="experienceSection" />
        <section className="py-5">
          <div className="container">
            <h2 className="text-center mb-5">A Life-Changing Experience</h2>
            <div className="row g-4">
              <div className="col-md-4" data-aos="fade-up">
                <div className="card h-100 border-0 shadow-sm">
                  <div className="card-body">
                    <h3 className="h5 mb-3">Natural Connection</h3>
                    <p>
                      Swim alongside spinner dolphins in their natural habitat,
                      experiencing their playful nature and profound
                      intelligence firsthand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                <div className="card h-100 border-0 shadow-sm">
                  <div className="card-body">
                    <h3 className="h5 mb-3">Daily Encounters</h3>
                    <p>
                      Enjoy twice-daily swimming sessions with dolphins, plus
                      snorkeling adventures exploring the vibrant coral reefs of
                      Sataya.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                <div className="card h-100 border-0 shadow-sm">
                  <div className="card-body">
                    <h3 className="h5 mb-3">Luxurious Comfort</h3>
                    <p>
                      Stay aboard a modern yacht with all meals provided,
                      experiencing stunning sunsets and sleeping under the
                      stars.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* 2025 Dates Section */}
        <section id="dates" className="py-5 bg-light">
          <div className="container">
            <h2 className="display-4 text-center mb-5">2025 Adventure Dates</h2>
            <div className="row g-4">
              <div className="col-md-6 col-lg-4" data-aos="fade-up">
                <div className="card h-100 border-0 shadow-sm">
                  <img
                    src="https://res.cloudinary.com/div038xr5/image/upload/c_pad,b_gen_fill,w_600,h_450,ar_4:3/v1736690452/ss-creations/dolphins/journey-family-3_s9h2oq.jpg"
                    alt="Family with dolphins"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h3 className="h4 mb-3">Family with Dolphins</h3>
                    <p className="text-muted mb-3">
                      A healing family experience
                    </p>
                    <ul className="list-unstyled">
                      <li className="mb-2"> April 26 - May 3, 2025</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="card h-100 border-0 shadow-sm">
                  <img
                    src="https://res.cloudinary.com/div038xr5/image/upload/c_pad,b_gen_fill,w_600,h_450,ar_4:3/v1736679347/ss-creations/dolphins/8f5af226-ef7a-401a-9e0f-fd37f86b2179_smfwqy.jpg"
                    alt="Self-Love Journey"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h3 className="h4 mb-3">Self-Love Journey</h3>
                    <p className="text-muted mb-3">
                      Through the dolphins' eyes
                    </p>
                    <ul className="list-unstyled">
                      <li className="mb-2"> May 4 - May 11, 2025</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-4"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="card h-100 border-0 shadow-sm">
                  <img
                    src="https://res.cloudinary.com/div038xr5/image/upload/c_pad,b_gen_fill,w_600,h_450,ar_4:3/v1736690694/ss-creations/dolphins/journey-goddes-2_qw2vik.jpg"
                    alt="Goddess Retreat"
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h3 className="h4 mb-3">Goddess Retreat</h3>
                    <p className="text-muted mb-3">
                      Embrace your inner goddess
                    </p>
                    <ul className="list-unstyled">
                      <li className="mb-2"> May 18 - May 25, 2025</li>
                      <li className="mb-2"> Sept 6 - Sept 13, 2025</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-5">
          <div className="container">
            <h2 className="text-center mb-5">Frequently Asked Questions</h2>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="accordion" id="faqAccordion">
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                      >
                        What's included in the experience?
                      </button>
                    </h3>
                    <div
                      id="faq1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body">
                        Your 7-night adventure includes accommodation on a
                        modern yacht, all meals and snacks, airport transfers,
                        twice-daily dolphin swimming sessions, snorkeling
                        guides, and pre-trip support including a Zoom meeting.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                      >
                        Do I need prior swimming experience?
                      </button>
                    </h3>
                    <div
                      id="faq2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body">
                        While you should be comfortable in water, you don't need
                        to be an expert swimmer. Our experienced guides will
                        ensure your safety and comfort throughout the
                        experience.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                      >
                        Are the dolphins in captivity?
                      </button>
                    </h3>
                    <div
                      id="faq3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqAccordion"
                    >
                      <div className="accordion-body">
                        No, you'll be swimming with completely free dolphins in
                        their natural habitat. The spinner dolphins choose to
                        visit Sataya Reef daily for rest, play, and social
                        interaction.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section
          className="py-5 text-dark"
          style={{ backgroundColor: 'var(--bs-primary-bg-subtle)' }}
        >
          <div className="container text-center ">
            <h2 className="mb-4 ">Ready for a Life-Changing Adventure?</h2>
            <p className="lead mb-4">
              Join us for an unforgettable experience with the dolphins of
              Sataya Reef
            </p>
            {!showForm && (
              <button
                className="btn btn-lg btn-dark text-light"
                type="button"
                onClick={toggleForm}
              >
                Book Your Journey
              </button>
            )}
            <div id="form">{showForm && <DolhphinsSignupForm />}</div>
          </div>
        </section>
        {/* Video Section */}
        <section className="py-5 bg-light">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="ratio ratio-16x9 shadow-lg">
                  <video
                    preload
                    controls
                    src="https://res.cloudinary.com/div038xr5/video/upload/v1737305446/ss-creations/dolphins/WhatsApp_Video_2025-01-15_at_08.44.34_taeuo3.mp4"
                    alt="Dolphins swimming"
                    className="rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container">
          <InstagramReels
            urls={[
              'https://www.instagram.com/saraskorjanc/reel/DE-JHW5o9EG/',
              'https://www.instagram.com/saraskorjanc/reel/DE4p6ANoBrF/',
              'https://www.instagram.com/saraskorjanc/reel/DE1r0pEo-HB/',
              'https://www.instagram.com/saraskorjanc/reel/DEw0HJ9I0t1/',
            ]}
          />
        </section>
      </div>
    </Layout>
  );
};

export default SatayaDolphinsPage;
