import React from "react";
import "./style.scss";
import SingleSponsoredPost from "../singleSponsoredPost";

const SponsoredPostsPlans = () => (
  <div className="row row-cols-1 row-cols-md-3 mb-3 text-center mt-md-3">
    <SingleSponsoredPost
      color="secondary"
      title="Basic"
      price="333 €"
      features={[
        "A single post on two platforms of your choice",
        "5 - 8 photos + content/post",
        "A minimum of 3 days stay",
      ]}
      buttonText="Sign up for free"
    />
    <SingleSponsoredPost
      color="primary"
      title="Extra"
      price="666 €"
      features={[
        "3 posts on all my social media platforms",
        "5 - 8 photos + content/post",
        "3 videos on TikTok",
        "A minimum of 5 days stay",
      ]}
      buttonText="Get started"
    />
    <SingleSponsoredPost
      color="tertiary"
      title="All In"
      price="1222 €"
      features={[
        "A minimum of 1 post per day on all my social media platforms",
        "5 - 8 photos or video/post with my face on it",
        "70 photos and 5 videos for you to use for promotional purposes.",
        "A minimum of 7 days stay",
      ]}
      buttonText="Get started"
    />
  </div>
);

export default SponsoredPostsPlans;
