import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import PhotoPackage from './photoPackage';
import VideoPackage from './videoPackage';
import {
  AccordionEventKey,
  AccordionSelectCallback,
} from 'react-bootstrap/esm/AccordionContext';
import AccordionHeading from './heading';

type Props = {};

const AdditionalPackagesAccordion = (props: Props) => {
  const [activeKey, setActiveKey] = useState<AccordionEventKey>(undefined);

  const onAccordionItemSelect: AccordionSelectCallback = selectedKey => {
    setActiveKey(selectedKey);
  };

  return (
    <Accordion onSelect={onAccordionItemSelect}>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <AccordionHeading
            title="Photos"
            icon="bi-image"
            isOpen={activeKey === '0'}
          />
        </Accordion.Header>
        <Accordion.Body>
          <PhotoPackage />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <AccordionHeading
            isOpen={activeKey === '1'}
            title="Videos"
            icon="bi-camera-video"
          />
        </Accordion.Header>
        <Accordion.Body>
          <VideoPackage />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AdditionalPackagesAccordion;
